import { List } from '@mantine/core';

import InformationMessage from '../../../../components/InformationMessage';
import { useI18n } from '../../../../contexts/I18nProvider';

export default function TutorialGma() {
  const { t } = useI18n();

  return (
    <InformationMessage
      variant="warning"
      title="En ce moment, vous consultez les documents disponibles dans votre
            coffre entreprise. Pour visualiser vos bulletins dans votre coffre
            personnel/privé, suivez ces étapes :"
      message={
        <List type="ordered" size="sm">
          <List.Item>{t('w.logout')}</List.Item>
          <List.Item>{t('gma.connectWithGMAButton')}</List.Item>
          <List.Item>{t('gma.returnToPayslipArea')}</List.Item>
        </List>
      }
    />
  );
}
